.fixed-plugin li > a,
.fixed-plugin .badge{
    transition: all .34s;
    -webkit-transition: all .34s;
    -moz-transition: all .34s;
}

.all-icons [class*="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
}

.places-buttons .btn{
    margin-bottom: 30px
}
.sidebar .nav > li.active-pro{
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a{
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3){
    text-align: center;
}

.fixed-plugin{
    position: absolute;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
}
.fixed-plugin .fa-cog{
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}
.fixed-plugin .dropdown-menu{
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}
.fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before{
    right: 10px;
    margin-left: auto;
    left: auto;
}
.fixed-plugin .fa-circle-thin{
    color: #FFFFFF;
}
.fixed-plugin .active .fa-circle-thin{
    color: #00bbff;
}

.footer-dropdown{
	top: -120px !important;
}

.footer-dropdown:before, .footer-dropdown:after{
	top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus{
    color: #777777;
    text-align: center;
}

.fixed-plugin img{
    border-radius: 0;
    width: 100%;
    max-height: 175px;
    margin: 0 auto;
}

.fixed-plugin .badge{
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}
.fixed-plugin .badge.active,
.fixed-plugin .badge:hover{
    border-color: #00bbff;
}

.fixed-plugin .badge-white{
    background-color: #FFFFFF;
}
.fixed-plugin .badge-black{
    background-color: #1DC7EA;
}
.fixed-plugin .badge-azure{
    background-color: #1DC7EA;
}
.fixed-plugin .badge-green{
    background-color: #87CB16;
}
.fixed-plugin .badge-orange{
    background-color: #FFA534;
}
.fixed-plugin .badge-purple{
    background-color: #9368E9;
}
.fixed-plugin .badge-red{
    background-color: #FB404B;
}
.fixed-plugin h5{
    font-size: 14px;
    margin: 10px;
}
.fixed-plugin .dropdown-menu li{
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container{
    width: 100%;
    height: 50px;
    min-height: inherit;
}
.fixed-plugin .pro-title{
    margin: 10px 0 5px 0;
    text-align: center;
}

.fixed-plugin #sharrreTitle{
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title{
    height: 30px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p{
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
}
.fixed-plugin .adjustments-line .switch{
    float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a{
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #ddd;
      margin: 0;
}
.fixed-plugin .dropdown-menu > li > a.img-holder{
      font-size: 16px;
      text-align: center;
      border-radius: 10px;
      background-color: #FFF;
      border: 3px solid #FFF;
      padding-left: 0;
      padding-right: 0;
      opacity: 1;
      cursor: pointer;
      max-height: 86px;
      overflow: hidden;
      padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus{
    background-color: transparent;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder{
    border-color: rgba(0, 187, 255, 0.53);;
}
.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder{
    border-color: #00bbff;
    background-color: #FFFFFF;
}

.fixed-plugin .dropdown-menu > li > a img{
    margin-top: auto;
}

.fixed-plugin .btn-social{
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}
.fixed-plugin .btn-social i{
    margin-right: 5px;
}
.fixed-plugin .btn-social:first-child{
    margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus{
    opacity: 1;
}


@media (min-width: 992px){
    .fixed-plugin .dropdown .dropdown-menu{
         -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
         -o-transform: translateY(-50%);
         -ms-transform: translateY(-50%);
         transform: translateY(-50%);
         top: 27px;
         opacity: 0;

         transform-origin: 0 0;
    }
    .fixed-plugin .dropdown.open .dropdown-menu{
         opacity: 1;

         -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
         -o-transform: translateY(-50%);
         -ms-transform: translateY(-50%);
         transform: translateY(-50%);

         transform-origin: 0 0;
    }

    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after{
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);

    }
    .fixed-plugin .dropdown-menu:before{
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid rgba(0,0,0,0.2);
        border-top: 16px solid rgba(0,0,0,0);
        right: -16px;
    }

    .fixed-plugin .dropdown-menu:after{
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid #FFFFFF;
        border-top: 16px solid rgba(0,0,0,0);
        right: -15px;
    }

    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }

    .fixed-plugin{
        top: 300px;
    }

}

@media (max-width: 991px){
    .fixed-plugin .dropdown-menu{
        right: 60px;
        width: 220px;
    }
    .fixed-plugin .dropdown-menu li{
        width: 50%;
    }

    .fixed-plugin li.adjustments-line,
    .fixed-plugin li.header-title,
    .fixed-plugin li.button-container{
        width: 100%;
        height: 55px;
        min-height: inherit;
    }

    .fixed-plugin .adjustments-line .switch{
        float: right;
        margin: 0 0px;
    }

    .fixed-plugin li.header-title{
        height: 40px;
    }
    .fixed-plugin .dropdown .dropdown-menu{
        top: -170px;
    }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}
.btn-social .fa {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
}
.btn-social.btn-round {
  padding: 9px 10px;
}
.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}
.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}
.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}
.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}
.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}
.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}
.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class*="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
}

.places-buttons .btn{
    margin-bottom: 30px
}
.sidebar .nav > li.active-pro{
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a{
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3){
    text-align: center;
}

.clickable-row{
  cursor: pointer;
}

.customTableBottomMargin{
    margin-bottom: 0px;
}

.clickable-button{
  cursor: pointer;
}

#login-container{
  background-repeat: round;
  height: 100%;
  width: 100%;
  padding: 0;
}

#login-container-cn{
    background-repeat: round;
    height: 100%;
    width: 100%;
    min-width: 923px;
    min-height: 622px;
    display: flex;
    padding: 0;
  }

#login-row{
  position: relative;
  top: 50%;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
}
#login-row-cn{
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
    margin: 0;
  }

#login-footer{
  color: white;
  text-align: right;
  padding: 5px 50px 0px 10px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

#login-form{
  max-width: 440px;
}

#login-form-cn{
    width: 460px;
    height: 622px
}

#login-button{
  background-color: RGB(255,155,46);
  border-color: RGB(255,155,46);
}

#login-button-cn{
    width: 300px;
    margin-left: unset !important;
    height: 36px;
    color: white;
    background-color: #369AFE;
    border-radius: 8px;
}

.full-page-background{
  position: absolute;
  z-index: -1000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.half-page-background{
  z-index: -1000;
  height: 100%;
  width: 50%;
  display: block;
  background-size: cover;
  background-position: center center;
}

.report-button{
  font-size: 20px;
  font-weight: bolder;
}

.center-text {
    text-align: center;
}

.react-loading{
    left: calc(100%/2);
    top: calc(100%/3);
    position: absolute;
    z-index: 20000;
}

.hidden{
    display:block;
}

.visible{
    display:block;
}

.ReadyAndNotAllDownloaded{
  color: #294d71;
  font-weight: bold !important;
}

.ReportNotDownloaded{
  color: #294d71;
  font-weight: bold;
}

.EmptyTableRow{
    background-color: lightgrey;
    text-align: center;
}

.hiddenRow{
    display:none;
}

.visibleRow{
    display:table-row;
}

.LoginButtonWrapper{
    overflow: auto;
}

.LoginButtonLeft{
    float: left;
    width: 100px;
}

.LoginButtonRight{
    float: left;
    width: 270px;
    margin-top: 4px;
    line-height: 1.5;
    color: RGB(255,156,46);
}
.resend-sms{
    position: absolute;
    top: 27px;
    right: 5px;
    width: 110px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D2D5E1;;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Avenir-Medium, Avenir;
    font-weight: 500;
    color: #D2D5E1;
    line-height: 19px;
}
.send-sms{
    position: absolute;
    top: 27px;
    right: 5px;
    width: 110px;
    height: 32px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D2D5E1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Avenir-Medium, Avenir;
    font-weight: 500;
    color: #636E95;
    line-height: 19px;

}

.form-control-cn{
    height: 42px;
    border-radius: 8px;
    border-radius: 10px;
    border: 1px solid #D2D5E1;
    background: #FAFCFE
}

#ResetPasswordModal{
    top: calc(50% - 140px);
}

#ResetPasswordModal .modal-dialog{
    width: 380px;
}

#resetHeader{
    border-bottom: 0;
}

#resetFooter{
    border-top: 0;
}

#LinkContainer{
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 60px;
    align-items: center;
}
#ForgotPasswordLink{
    cursor: pointer;
}

#LoginWithSmsLink{
    cursor: pointer;
    width: 115px;
    height: 24px;
    font-size: 14px;
    font-family: Avenir-Medium, Avenir;
    font-weight: 500;
    color: #369AFE;
    white-space: nowrap;
    line-height: 24px;
}

#ForgotPasswordLinkCn{
    cursor: pointer;
    white-space: nowrap;
    width: 115px;
    height: 24px;
    font-size: 14px;
    font-family: Avenir-Medium, Avenir;
    font-weight: 500;
    color: #97A0C3;
    line-height: 24px;
}

#PatientInfo{
    white-space: pre-wrap;
}

.PatientInfoValue{
    font-weight: bold;
    margin-right: 10px;
}

.RefreshTable{
    background-color: white;
    padding-bottom: 5px !important;
}

.RefreshTableIcon {
    cursor: pointer;
    color: RGB(43, 197, 234);
    margin: 5px 0 0 -10px;
}

.RefreshTableText {
    color: RGB(182, 182, 182);
    margin-left: 5px;
}

.table-full-width{
    margin: 0;
}

/* Explicitly setting height so that main panel always takes up the entire screen height instead
    of resizing to a smaller height when there is no content in TableList.jsx */
.main-panel {
    min-height: 100%;
}

/* We need to use vh instead of % in Firefox when parent's height is not set for this to work properly */
.main-panel > .content {
    min-height: calc(100vh - 111px)!important;
}

.fa-spin-reverse {
    -webkit-animation: fa-spin-reverse 2s infinite linear;
    animation: fa-spin-reverse 2s infinite linear;
  }
  @-webkit-keyframes fa-spin-reverse {
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    0% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes fa-spin-reverse {
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    0% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

#card-patient-header {
    padding-top: 5px;
    display: flex;
}

.patient-invite-button-wrapper {
    margin-right: 5%;
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.patient-info-wrapper {
    width: 60%;
}

.patient-info-wrapper .info-top-section,
.patient-info-wrapper .info-bottom-section {
    width: 100%;
    display: block;
}

.form-horizontal .has-feedback .form-control-feedback {
    top: 10px;
}

.has-success .form-control-feedback,
.has-success .form-control {
 color: #3c763d;
}

.has-error .form-control-feedback,
.has-error .form-control {
    color: #a94442;
}

.has-error .form-control:focus {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
}

.has-success .form-control:focus {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #67b168;
}

.patient-invite-button-wrapper .disabled-button {
    background-color: #b9b5af;
    border-color: #b9b5af;
}

.patient-invite-button-wrapper .enabled-button,
.patient-invite-button-wrapper .enabled-button:hover,
.patient-invite-button-wrapper .enabled-button:focus {
    background-color: #ff9b2e;
    border-color: #ff9b2e;
}
.favorite {
  padding: 20px;
  text-align: center;
}
.favorite-close {
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
}
.favorite-title {
  color: #242f57;
  font-size: 22px;
  font-weight: 900;
  float: left;
  margin: 16px 0 0 0;
}
.favorite-body {
  margin: 80px 0 0 0;
  width: 100%;
  text-align: left;
}
.favorite-text-field-label {
  width: 100%;
  margin: 0;
  font-size: 14px;
  color: #97a0c3;
  margin: 0 0 0 5px;
}
.confirm-text-field-label {
  width: 100%;
  margin: 0;
  font-size: 14px;
  color: #636E95;
  margin: 0 0 0 5px;
}
.favorite-text-field {
  height: 36px;
  width: 100%;
  background-color: #fafcfe;
  margin: 13px 0 0 0;
  border-radius: 8px;
  /* display: inline-flex;
  align-items: center;
  justify-content: left; */
}
.favorite-bottom {
  margin: 25px 0 0 0;
  float: right;
}
