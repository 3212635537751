.spinner-box-page {
  position: fixed;
  background-color: #ffffff;
  /* background-color: #fafbfc; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.spinner-base {
  height: 9em;
  left: calc(50% + 3em);
  top: calc(50% - 5em);
  margin: -7.5em;
  padding: 0em;
  position: absolute;
  width: 9em;
  transform: rotateX(45deg) rotateZ(45deg);
  transform-style: preserve-3d;
}

.spinner-cube,
.spinner-cube:after,
.spinner-cube:before {
  content: "";
  float: left;
  height: 3em;
  position: absolute;
  width: 3em;
}

/* Top */
.spinner-cube {
  background-color: #369afe;
  position: relative;
  transform: translateZ(3em);
  transform-style: preserve-3d;
  transition: 0.25s;
  box-shadow: 13em 13em 1.5em rgba(0, 0, 0, 0.1);
  animation: anim 1s infinite;
}
.spinner-cube:after {
  background-color: #1a71cb;
  transform: rotateX(-90deg) translateY(3em);
  transform-origin: 100% 100%;
}
.spinner-cube:before {
  background-color: #246fbb;
  transform: rotateY(90deg) translateX(3em);
  transform-origin: 100% 0;
}
.spinner-cube:nth-child(1) {
  animation-delay: 0.05s;
}
.spinner-cube:nth-child(2) {
  animation-delay: 0.1s;
}
.spinner-cube:nth-child(3) {
  animation-delay: 0.15s;
}
.spinner-cube:nth-child(4) {
  animation-delay: 0.2s;
}
.spinner-cube:nth-child(5) {
  animation-delay: 0.25s;
}
.spinner-cube:nth-child(6) {
  animation-delay: 0.3s;
}
.spinner-cube:nth-child(7) {
  animation-delay: 0.35s;
}
.spinner-cube:nth-child(8) {
  animation-delay: 0.4s;
}
.spinner-cube:nth-child(9) {
  animation-delay: 0.45s;
}

@keyframes anim {
  50% {
    transform: translateZ(0.5em);
  }
}
